import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { LuGlobe } from "react-icons/lu";
import { ImLinkedin } from "react-icons/im";
import { IoEllipse } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { IoIosMore } from "react-icons/io";
import "./CompanyInfo.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
type NextCall = {
  title: string;
  date: string;
};

type CompanyData = {
  company_name: string;
  address: string;
  company_type: string;
  next_call: NextCall;
};

type Props = {
  data: CompanyData;
  activeTab: string;
  state: string;
  onTabChange: (tab: string) => void;
  onIconChange: (tab: string) => void;
  accId: string | null;
  authToken: string | null;
  companyName?: string;
};

const CompanyInfo: React.FC<Props> = ({
  data,
  activeTab,
  state,
  onTabChange,
  onIconChange,
  accId,
  authToken,
  companyName,
}) => {
  const handleTabClick = (tab: string) => {
    onTabChange(tab);
  };
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // Parse accId from query parameters
  const CompanyName = searchParams.get("compName");
  function removeFragmentIdentifier(companyName: any) {
    return companyName.replace(/\..*$/, ""); // Removes everything after `#`
  }

  const cleanCompanyName = removeFragmentIdentifier(CompanyName);

  return (
    <div className={`top-header${state === "collapsed" ? "" : "-expanded"}`}>
      <div
        className="back-section"
        onClick={() => {
          onIconChange("Home");
          navigate("/");
        }}
      >
        <GoArrowLeft className="back-icon" />
        <a href="#" className="back-name">
          Back to Accounts
        </a>
      </div>
      <header className="company-header">
        <div className="company-logo-name">
          {/* <img
            src={companylogo}
            alt={`${data.company_name} Logo`}
            className="company-logo"
          /> */}
          <div>
            <div className="company-name-icons">
              <h3 className="company_name">{cleanCompanyName}</h3>
              <div className="icon-buttons">
                <button className="globe-button" aria-label="Company Website">
                  <LuGlobe />
                </button>
                <button
                  className="linkedin-button"
                  aria-label="LinkedIn Profile"
                >
                  <ImLinkedin />
                </button>
              </div>
            </div>
            {/* <div className="address-type">
              <p>
                {data.address}
                <IoEllipse className="ellipse-icon" />
                {data.company_type}
              </p>
            </div> */}
          </div>
        </div>
        {/* <div className="next-call-section">
          <div className="call-title">
            <h3 className="next-call">Next call</h3>
            <h3>{data.next_call.title}</h3>
          </div>
          <p>{data.next_call.date}</p>
        </div>
        <div className="calendar-section">
          <div className="icon-name">
            <CiCalendar className="calendar-icon" />
            Open Calendar
          </div>
          <IoIosMore className="more-icon" />
        </div> */}
      </header>
      <div className="tabs">
        <button
          className={`account_overview ${
            activeTab === "account_overview" ? "active" : ""
          }`}
          onClick={() => {
            navigate(
              `/documents/account_overview?accId=${accId}&compName=${companyName}`
            );
            handleTabClick("account_overview");
          }}
        >
          Account Overview
        </button>
        <button
          className={`meeting_prep ${
            activeTab === "meeting_prep" ? "active" : ""
          }`}
          onClick={() => {
            navigate(
              `/documents/meeting_prep?accId=${accId}&compName=${companyName}`
            );
            handleTabClick("meeting_prep");
          }}
        >
          Meeting Prep
        </button>
        <button
          className={`call_transcripts ${
            activeTab === "call_transcripts" ? "active" : ""
          }`}
          onClick={() => {
            navigate(
              `/documents/call_transcript?accId=${accId}&compName=${companyName}`
            );
            handleTabClick("call_transcripts");
          }}
        >
          Calls/Meetings
        </button>
      </div>
    </div>
  );
};

export default CompanyInfo;
