import React, {
  forwardRef,
  ReactNode,
  useState,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import "./BaseCard.css";
import { IoIosCheckmarkCircleOutline, IoIosClose } from "react-icons/io";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";
import bird from "../../assets/Sparrow_bird_transparent_bg.png";
import toast, { Toaster } from "react-hot-toast";
import { LuCopy } from "react-icons/lu";
import { FiPlusCircle } from "react-icons/fi";
import Note from "../Note/Note";
import { Tooltip } from "@mui/material";

interface Props {
  children: ReactNode;
  title: string;
  modifyclass?: string;
  button?: any;
  buttonName?: any;
  collapse?: boolean;
  sideCardName?: any;
  sideCard?: React.ReactElement;
  type?: string;
  notes_color?: string;
  text_color?: string;
  call?: boolean;
}

const formatTitle = (title: string): string => {
  return title
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const BaseCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      title,
      modifyclass,
      button,
      buttonName,
      collapse,
      sideCardName,
      sideCard,
      type,
      notes_color,
      text_color,
      call,
    },
    ref
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(collapse);
    const cardRef = useRef<HTMLDivElement>(null);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isNoteInputVisible, setIsNoteInputVisible] = useState(false);
    const [noteContent, setNoteContent] = useState("");

    const handleCardClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    const notify = (e: React.MouseEvent) => {
      // Prevent click event propagation
      e.stopPropagation();

      // Get the content you want to copy, excluding the header
      const contentToCopy =
        cardRef.current?.querySelector(".base-content")?.textContent;

      // Check if content exists and copy it to the clipboard
      if (contentToCopy) {
        navigator.clipboard
          .writeText(contentToCopy)
          .then(() => {
            // Show the toast notification after successful copy
            toast.custom(
              <div className="toast-custom">
                <IoIosCheckmarkCircleOutline size="16px" color="#15803D" />
                <p>Content copied to clipboard</p>
              </div>,
              { duration: 3000 }
            );
          })
          .catch((err) => {
            console.error("Failed to copy content: ", err);
          });
      }
    };

    const toggleCollapse = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsCollapsed((prev) => !prev); // Use functional state update to ensure correct toggling
    };

    const toggleSidePanel = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsSidePanelOpen((prev) => !prev); // Use functional state update to ensure correct toggling
    };

    const onClose = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsSidePanelOpen(false);
    };

    const toggleNoteInput = () => {
      setIsNoteInputVisible((prev) => !prev);
    };

    useImperativeHandle(ref, () => cardRef.current as HTMLDivElement);

    const displayTitle = formatTitle(title);

    // Detect clicks outside of the card or side panel
    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) => {
        if (cardRef.current && !cardRef.current.contains(e.target as Node)) {
          setIsSidePanelOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    const clonedChildren = React.cloneElement(
      children as React.ReactElement<any>,
      {
        isCollapsed,
        isSidePanelOpen: false,
      }
    );

    // Check if clonedChildren.props.values exists and has a length of 0
    if (
      !clonedChildren.props.values ||
      clonedChildren.props.values.length === 0
    ) {
      return null;
    }

    return (
      <div
        className={`outercard${modifyclass ? modifyclass : ""}`}
        ref={cardRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <header>
          <h4 onClick={toggleCollapse}>{displayTitle}</h4>

          <div className="top-button">
            {isHovered && !call && type === "account_strategy" && (
              <Tooltip title="Add Note" arrow>
                <button className="b2" onClick={toggleNoteInput}>
                  <FiPlusCircle size="14.13px" />
                </button>
              </Tooltip>
            )}
            {isHovered && (
              <Tooltip title="Copy Content" arrow>
                <button className="b1" onClick={notify}>
                  <LuCopy size="13.33px" />
                </button>
              </Tooltip>
            )}
            <button
              className={`${isHovered ? "hovered" : "b3"}`}
              onClick={toggleCollapse}
            >
              {isCollapsed ? (
                <IoChevronDown size="20px" style={{ color: "#44403C" }} />
              ) : (
                <IoChevronUp size="20px" style={{ color: "#44403C" }} />
              )}
            </button>
          </div>
        </header>

        {isNoteInputVisible && !isSidePanelOpen && (
          <div className="note-input">
            <textarea
              className="note-textarea"
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              placeholder="Enter your note here"
            />
            <button
              className="note-save-btn"
              onClick={() => setIsNoteInputVisible(false)}
            >
              Save Note
            </button>
          </div>
        )}

        {/* {!isCollapsed && type === "account_strategy" && !call && (
          <Note notes_color={notes_color || ""} text_color={text_color || ""} />
        )} */}
        <div
          className="base-content"
          onClick={() => setIsNoteInputVisible(false)}
        >
          {clonedChildren}

          {isCollapsed && button && (
            <div className="collapsed-button">
              <button className="side-panel-toggle" onClick={toggleSidePanel}>
                <img src={bird} className="bird" alt="Bird" />
                {buttonName}
                <FaChevronRight style={{ color: "#44403C" }} />
              </button>
            </div>
          )}
        </div>
        {/* {isCollapsed && type === "account_strategy" && !call && (
          <Note notes_color={notes_color || ""} text_color={text_color || ""} />
        )} */}
        {!isCollapsed && (
          <div className="Bottom-button">
            <Toaster position="bottom-right" reverseOrder={false} />
            {button && (
              <button className="side-panel-toggle" onClick={toggleSidePanel}>
                <img src={bird} className="bird" alt="Bird" />
                {isSidePanelOpen ? "Close Panel" : buttonName}
                <FaChevronRight style={{ color: "#44403C" }} />
              </button>
            )}
          </div>
        )}
        {isSidePanelOpen && (
          <div className="side-panel">
            <div className="side-panel-header">
              <div className="panel_title">
                <h4>{displayTitle}</h4>
              </div>
              <div className="top-button">
                {!call && type === "account_strategy" && (
                  <Tooltip title="Add Note" arrow>
                    <button className="b2" onClick={toggleNoteInput}>
                      <FiPlusCircle size="14.13px" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip title="Copy Content" arrow>
                  <button className="b1" onClick={notify}>
                    <LuCopy size="13.33px" />
                  </button>
                </Tooltip>
                <button className="side-panel-close" onClick={onClose}>
                  <IoIosClose />
                </button>
              </div>
            </div>
            <div className="Recommend">
              <h3 style={{ margin: 0 }}>{formatTitle(sideCardName)}</h3>
              {isNoteInputVisible && isSidePanelOpen && (
                <div className="note-input">
                  <textarea
                    className="note-textarea"
                    value={noteContent}
                    onChange={(e) => setNoteContent(e.target.value)}
                    placeholder="Enter your note here"
                  />
                  <button
                    className="note-save-btn"
                    onClick={() => setIsNoteInputVisible(false)}
                  >
                    Save Note
                  </button>
                </div>
              )}
              {React.cloneElement(sideCard as React.ReactElement<any>, {
                isCollapsed,
                isSidePanelOpen,
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default BaseCard;
