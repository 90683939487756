import React from "react";
import { Container, Typography, Box, Stack } from "@mui/material";
import "./Extension.css";
import useFetchData from "../../parsedata";
import { constant } from "../../constant";

const Extension = ({ accId, authToken }: any) => {
  const { extension } = useFetchData(accId, authToken);
  const sortedSections = [...extension].sort((a, b) => a.order - b.order);

  return (
    <Container
      maxWidth="sm"
      sx={{ padding: "16px", fontFamily: '"Inter", sans-serif' }}
    >
      <button
        onClick={() => {
          const url = constant.extensionURL.replace("${accId}", accId);
          chrome.tabs.create({ url: url });
        }}
        className="dive-deeper"
      >
        Dive Deeper
      </button>

      <Box className="extension-cards">
        <Stack spacing={2}>
          {sortedSections.map((item, index) => (
            <Typography key={index} variant="body1">
              {item.content}
            </Typography>
          ))}
        </Stack>
      </Box>
    </Container>
  );
};

export default Extension;
