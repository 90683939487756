import React, { useState, useEffect, useRef } from "react";
import "./Person.css";
import avatar from "../../assets/avatar.png";
import { PersonsProps } from "../../updated-interface";
import { IoChevronForward, IoChevronDown, IoChevronUp } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import useFetchData from "../../parsedata";
import BaseCard from "../BaseCard/BaseCard";
import { Alert } from "@mui/material";

interface PersonDetails {
  name?: string;
  role?: string;
  image?: string;
}

interface Section {
  id: string;
  title: string;
  content?: React.ReactNode;
}

interface RightSidePanelProps {
  person: PersonDetails | null;
  onClose: () => void;
  accId: string | null;
}

const RightSidePanel: React.FC<RightSidePanelProps> = ({
  person,
  onClose,
  accId,
}) => {
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const { expandSection } = useFetchData(accId); // Assuming this returns an array of Section
  const panelRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sortedSections = [...expandSection].sort((a, b) => a.order - b.order);

  return (
    <div className="right-side-panel" ref={panelRef}>
      <div className="panel-header">
        <div className="title_panel" onClick={toggleDropdown}>
          <h2>{person?.name}</h2>
          {isDropdownVisible ? <IoChevronUp /> : <IoChevronDown />}
        </div>
        <button className="Right_Close" onClick={onClose}>
          <IoIosClose className="close" size="30px" />
        </button>
      </div>

      {/* Dropdown content */}
      {isDropdownVisible && (
        <ul className="dropdown-content">
          {sortedSections.map((section) => (
            <li key={section.id}>
              <a
                href={`#${section.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleScroll(section.id);
                }}
              >
                {section.title}
              </a>
            </li>
          ))}
        </ul>
      )}

      <div className="panel-body">
        {sortedSections.map((section) => (
          <BaseCard
            modifyclass="sidepanel"
            key={section.id}
            title={section.title}
            ref={(el) => (sectionRefs.current[section.id] = el)}
          >
            {section.content}
          </BaseCard>
        ))}
      </div>
    </div>
  );
};

const Person: React.FC<PersonsProps> = ({
  type,
  values,
  accId,
  is_expand,
  call,
}) => {
  const [selectedPerson, setSelectedPerson] = useState<PersonDetails | null>(
    null
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";

  // Normalize values to always be an array
  const normalizedValues = Array.isArray(values) ? values : [values];

  const handleCardClick = (person: PersonDetails) => {
    setSelectedPerson(person);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setSelectedPerson(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  try {
    if (call) {
      return (
        <div
          ref={containerRef}
          style={{ display: "flex", columnGap: "12px", flexWrap: "wrap" }}
        >
          {normalizedValues.map((value, index) =>
            value?.type?.value === "buyer" ? (
              <div
                key={`${value?.name?.value}-${value?.role?.value}-${index}`}
                role="button"
                tabIndex={0}
                onClick={() =>
                  handleCardClick({
                    name: value?.name?.extra_3 ? value.name?.value : undefined,
                    role: value?.role?.extra_3 ? value.role?.value : undefined,
                    image: value?.image?.extra_3
                      ? value.image?.value
                      : undefined,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleCardClick({
                      name: value?.name?.extra_3
                        ? value.name?.value
                        : undefined,
                      role: value?.role?.extra_3
                        ? value.role?.value
                        : undefined,
                      image: value?.image?.extra_3
                        ? value.image?.value
                        : undefined,
                    });
                  }
                }}
              >
                <Peoples
                  name={value?.name?.extra_3 ? value.name?.value : undefined}
                  role={value?.role?.extra_3 ? value.role?.value : undefined}
                  image={value?.image?.extra_3 ? value.image?.value : undefined}
                />
              </div>
            ) : null
          )}

          {selectedPerson && is_expand && (
            <RightSidePanel
              person={selectedPerson}
              onClose={() => setSelectedPerson(null)}
              accId={accId}
            />
          )}
        </div>
      );
    }
    return (
      <div
        ref={containerRef}
        style={{ display: "flex", columnGap: "12px", flexWrap: "wrap" }}
      >
        {normalizedValues.map((value, index) =>
          value?.type?.value === "buyer" ? (
            <div
              key={`${value?.name?.value}-${value?.role?.value}-${index}`}
              role="button"
              tabIndex={0}
              onClick={() =>
                handleCardClick({
                  name: value?.name?.[cardType] ? value.name?.value : undefined,
                  role: value?.role?.[cardType] ? value.role?.value : undefined,
                  image: value?.image?.[cardType]
                    ? value.image?.value
                    : undefined,
                })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleCardClick({
                    name: value?.name?.[cardType]
                      ? value.name?.value
                      : undefined,
                    role: value?.role?.[cardType]
                      ? value.role?.value
                      : undefined,
                    image: value?.image?.[cardType]
                      ? value.image?.value
                      : undefined,
                  });
                }
              }}
            >
              <Peoples
                name={value?.name?.[cardType] ? value.name?.value : undefined}
                role={value?.role?.[cardType] ? value.role?.value : undefined}
                image={
                  value?.image?.[cardType] ? value.image?.value : undefined
                }
              />
            </div>
          ) : null
        )}

        {selectedPerson && is_expand && (
          <RightSidePanel
            person={selectedPerson}
            onClose={() => setSelectedPerson(null)}
            accId={accId}
          />
        )}
      </div>
    );
  } catch (error) {
    return <Alert severity="error">Error Processing Person card</Alert>;
  }
};

interface DetailsProps {
  name?: string;
  role?: string;
  image?: string;
}

const Peoples: React.FC<DetailsProps> = ({ name, role, image }) => {
  return (
    <div className="Person_component">
      <div className="person_rights">
        <img src={image || avatar} alt="user" className="avatar_1" />
        <div className="name_role">
          {name ? (
            <span className="person-name_1">
              <text>{name}</text>
            </span>
          ) : null}
          {role ? <div className="person-position_1">{role}</div> : null}
        </div>
        <div className="fa-icon-right">
          <IoChevronForward style={{ color: "#44403C" }} size="25px" />
        </div>
      </div>
    </div>
  );
};

export default Person;
