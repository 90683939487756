import React from "react";
import "./CardBant25.css";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { RxExclamationTriangle, RxCheckbox } from "react-icons/rx";
import { CardBantDetails, CardBantProps } from "../../updated-interface";

interface Field {
  badge_color: string;
  value?: string;
  element_name?: string;
  is_detailed?: boolean;
  is_as_enabled?: boolean;
  is_mp_enabled?: boolean;
  extra_3: boolean;
}

const iconMap: { [key: string]: JSX.Element } = {
  budget: <AiOutlineQuestionCircle className="question-icon" />,
  need: <RxCheckbox className="checkbox-icon" />,
  authority: <RxCheckbox className="checkbox-icon" />,
  timeline: <RxExclamationTriangle className="timeline-icon" />,
};

const CardBant25: React.FC<CardBantProps> = ({
  type,
  values,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";

  const isFieldEnabled = (field: Field | undefined): boolean => {
    if (!field) return false;
    if (isSidePanelOpen) {
      return !!field.is_detailed;
    }
    return !!field[cardType];
  };
  const isKeyOfCardBantDetails = (
    key: string
  ): key is keyof CardBantDetails => {
    return key in values[0];
  };
  if (call) {
    return (
      <div>
        <div
          className={`methodology-25-content ${isCollapsed ? "collapsed" : ""}`}
        >
          {Object.entries(values[0]).map(([key, field]) => {
            const fieldData = field as Field;
            if (key.includes("_state") || key === "section_id") {
              return null;
            }
            if (fieldData.extra_3) {
              // Access state data for "badge" if present
              const stateKey = `${key}_state` as keyof CardBantDetails;
              const stateData = values[0][stateKey] as Field | undefined;
              var style = {},
                icon = <AiOutlineQuestionCircle className="question-icon" />;
              if (stateData?.value === "Met") {
                style = { background: " #D9F99D", color: "#1A2E05" };
                icon = <RxCheckbox className="checkbox-icon" />;
              } else if (stateData?.value === "Unmet") {
                style = { background: " #FECACA", color: " #450A0A" };
                icon = <RxExclamationTriangle className="timeline-icon" />;
              } else if (stateData?.value === "Unclear") {
                style = { background: "#FDE68A", color: "#451A03" };
                icon = <AiOutlineQuestionCircle className="question-icon" />;
              }

              return (
                <p key={key}>
                  <strong className="section" style={style}>
                    {icon}
                    {fieldData.element_name?.replace(/_/g, " ")}
                  </strong>
                  {!shouldCollapse && fieldData.value}
                </p>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div
        className={`methodology-25-content ${isCollapsed ? "collapsed" : ""}`}
      >
        {Object.entries(values[0]).map(([key, field]) => {
          const fieldData = field as Field;
          if (key.includes("_state") || key === "section_id") {
            return null;
          }

          if (isFieldEnabled(fieldData)) {
            // Access state data for "badge" if present
            const stateKey = `${key}_state` as keyof CardBantDetails;
            const stateData = values[0][stateKey] as Field | undefined;
            var style = {},
              icon = <AiOutlineQuestionCircle className="question-icon" />;
            if (stateData?.value === "Met") {
              style = { background: " #D9F99D", color: "#1A2E05" };
              icon = <RxCheckbox className="checkbox-icon" />;
            } else if (stateData?.value === "Unmet") {
              style = { background: " #FECACA", color: " #450A0A" };
              icon = <RxExclamationTriangle className="timeline-icon" />;
            } else if (stateData?.value === "Unclear") {
              style = { background: "#FDE68A", color: "#451A03" };
              icon = <AiOutlineQuestionCircle className="question-icon" />;
            }

            return (
              <p key={key}>
                <strong className="section" style={style}>
                  {icon}
                  {fieldData.element_name?.replace(/_/g, " ")}
                </strong>
                {!shouldCollapse && fieldData.value}
              </p>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default CardBant25;
