import React, { useState, useEffect } from "react";
import {
  LuPanelLeftOpen,
  LuMessageSquare,
  LuBook,
  LuPanelLeftClose,
} from "react-icons/lu";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import "./Main_Sidebar.css";
import { PiHandshake } from "react-icons/pi";
import { useLocation } from "react-router-dom";
interface SidebarProps {
  onToggle: (state: string) => void;
  accId: string | null;
  onActiveTab: (arg0: string) => void;
  onIconChange: (icon: string) => void;
  disableNavigation: boolean;
  companyName?: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  onToggle,
  onActiveTab,
  accId,
  onIconChange,
  disableNavigation,
  companyName,
}) => {
  const [activePage, setActivePage] = useState<string>("Home");
  const [collapsed, setCollapsed] = useState(true);
  const [disableNav, setDisableNav] = useState<boolean>(disableNavigation);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };
  const location = useLocation();

  useEffect(() => {
    const state = collapsed ? "collapsed" : "expanded";
    onToggle(state);
  }, [collapsed, onToggle]);

  const isPageReload = (): boolean => {
    const [navigation] = performance.getEntriesByType(
      "navigation"
    ) as PerformanceNavigationTiming[];
    if (navigation?.type === "reload") {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActivePage("Home");
    } else if (
      path.includes("account_overview") ||
      path.includes("meeting_prep") ||
      path.includes("call_transcript")
    ) {
      if (isPageReload()) {
        setDisableNav(false);
      } else {
        setDisableNav(disableNavigation);
      }
      setActivePage("Accounts");
    } else if (path.includes("chat")) {
      setActivePage("Chat");
    } else if (path.includes("Admin")) {
      setActivePage("Admin");
    }
  }, [location]);

  return (
    <nav className={`sidebar-main ${collapsed ? "collapsed" : ""}`}>
      <div
        className="icon"
        onClick={toggleSidebar}
        role="button"
        aria-label="Toggle Sidebar"
      >
        {collapsed ? (
          <LuPanelLeftOpen />
        ) : (
          <>
            <LuPanelLeftClose /> <span>Collapse</span>
          </>
        )}
      </div>

      <div className="page-icon">
        <div
          className={`icon-label ${collapsed ? "collapsed" : ""} ${
            activePage === "Home" ? "active" : ""
          }`}
          onClick={() => {
            navigate(`/`);
            onIconChange("Home");
          }}
          role="button"
          aria-label="Go to Home"
        >
          <FiHome />
          {!collapsed && <span className="icon-text">Home</span>}
        </div>
        <div
          className={`icon-label ${collapsed ? "collapsed" : ""}  ${
            activePage === "Accounts" ? "active" : ""
          } ${disableNav ? "disabled" : ""}`}
          onClick={() => {
            navigate(
              `/documents/account_overview?accId=${accId}&compName=${companyName}`
            );
            onIconChange("Accounts");
            onActiveTab("account_overview");
          }}
          role="button"
          aria-label="Go to Accounts"
        >
          <LuBook />
          {!collapsed && <span className="icon-text">Accounts</span>}
        </div>
        <div
          className={`icon-label  ${collapsed ? "collapsed" : ""} ${
            activePage === "Chat" ? "active" : ""
          }`}
          onClick={() => {
            onIconChange("Chat");
            navigate("/chat");
          }}
          role="button"
          aria-label="Go to Chat"
        >
          <LuMessageSquare />
          {!collapsed && <span className="icon-text">Chat</span>}
        </div>
        <div
          className={`icon-label ${collapsed ? "collapsed" : ""} ${
            activePage === "Admin" ? "active" : ""
          }`}
          onClick={() => {
            navigate(`/Admin`);
            onIconChange("Admin");
          }}
          role="button"
          aria-label="Go to Admin"
        >
          <PiHandshake />
          {!collapsed && <span className="icon-text">Admin</span>}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
