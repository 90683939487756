import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HomePage.css";
import apiClient from "../../apiService";
import AccountTable from "./AccountTable/Account_Table";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation } from "react-router-dom";

interface HomePageProps {
  setAccId: (id: string) => void;
  accId: string | null;
  state: string;
  onIconChange: (icon: string) => void;
  onActiveTab: (tab: string) => void;
  selectedText: string;
  setCompanyName: (name: string) => void;
  isChromeExtension: boolean;
  authToken: string | null;
  onSubmit: (submitted: boolean) => void;
  companyName?: string;
}

const HomePage: React.FC<HomePageProps> = ({
  setAccId,
  accId,
  state,
  onIconChange,
  onActiveTab,
  selectedText,
  setCompanyName,
  isChromeExtension,
  authToken,
  onSubmit,
  companyName,
}) => {
  const [query, setQuery] = useState<string>(selectedText); // Track user input
  const [searchResults, setSearchResults] = useState<any[]>([]); // Store search results
  const [error, setError] = useState<string>(""); // Handle errors
  const [accountSelected, setAccountSelected] = useState<boolean>(false); // Track if an account is selected
  const navigate = useNavigate(); // Hook to navigate
  let location = useLocation();

  useEffect(() => {
    if (isChromeExtension) {
      setQuery(selectedText);
      handleSearchChange(selectedText);
    }
  }, [selectedText]);

  // Handle input change and fetch search results
  const handleSearchChange = async (newQuery: string) => {
    setQuery(newQuery);

    if (newQuery.trim() === "") {
      setSearchResults([]);
      setAccountSelected(false); // Reset account selection
      return;
    }

    try {
      const queryParams = new URLSearchParams(location.search);

      const domain = queryParams.get("domain");
      const response = await apiClient.get("/search", {
        params: domain
          ? { query: newQuery, domain: domain }
          : { query: newQuery },
      });

      if (response.data.length > 0) {
        setSearchResults(response.data); // Set results for dropdown
        console.log(response.data);
        setError("");
        if (isChromeExtension && response.data.length === 1) {
          handleAccountSelect(response.data[0]);
        }
      } else {
        console.log(response.data);
        setSearchResults([]);
      }
    } catch (err: any) {
      setError(err.response?.data?.detail || "An error occurred");
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (isChromeExtension && accId) {
      handleSubmit();
    }
  }, [accId]);

  // Handle account selection from dropdown
  const handleAccountSelect = (account: {
    account_id: string;
    account_name: string;
  }) => {
    setAccId(account.account_id);
    setQuery(account.account_name); // Set the selected account's name in the input field
    setCompanyName(account.account_name);

    setSearchResults([]); // Clear search results
    setAccountSelected(true); // Mark that an account has been selected
  };

  // Handle submit button click
  const handleSubmit = () => {
    if (accId) {
      onSubmit(false);
      navigate(
        `/documents/account_overview?accId=${accId}&compName=${companyName}`
      ); // Navigate with query parameter
      onIconChange("Accounts");
      onActiveTab("account_overview");
    } else {
      alert("Provide a valid Account name");
    }
  };

  return (
    <div className={`HomePage${state === "collapsed" ? "" : "-expanded"}`}>
      <h3>All Accounts</h3>
      <div style={{ position: "relative", width: "100%" }}>
        <AiOutlineSearch
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#18181B",
          }}
        />
        <input
          type="text"
          placeholder="Search by company name, account owner or deal stage"
          value={query}
          onChange={(e) => handleSearchChange(e.target.value)} // Fetch results on input change
        />
      </div>
      {/* Display search results as a dropdown */}
      {searchResults.length > 0 && (
        <ul className="search-dropdown">
          {searchResults.map((account) => (
            <li
              key={account.account_id}
              onClick={() => handleAccountSelect(account)} // Handle account selection
              className="search-item"
            >
              {account.account_name}
            </li>
          ))}
        </ul>
      )}

      {accountSelected && (
        <button className="Home-Submit" onClick={handleSubmit}>
          Submit
        </button>
      )}
      {/* <AccountTable /> */}
    </div>
  );
};

export default HomePage;
