import React from "react";
import "./CardFormat25.css";
import { Card25Props } from "../../updated-interface";
import { RxCheckbox } from "react-icons/rx";
import { HiExternalLink } from "react-icons/hi";
import { Alert } from "@mui/material";
const CardFormat25: React.FC<Card25Props> = ({
  type,
  values,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";
  const normalizeValues = (values: any) => {
    return Array.isArray(values) ? values : [values];
  };

  try {
    const normalizedValues = normalizeValues(values);
    if (call) {
      return (
        <div>
          {shouldCollapse ? (
            <div className="card-collapse">
              {normalizedValues.map((value, index) => {
                const titleEnabled = value.title?.extra_3;

                return titleEnabled ? (
                  <div
                    key={index}
                    className="badge"
                    style={{
                      backgroundColor: value.title?.badge_color
                        ? value.title?.badge_color
                        : "#bae6fd",
                    }}
                  >
                    {value.title?.value}
                  </div>
                ) : null;
              })}
            </div>
          ) : null}

          {!shouldCollapse ? (
            <div>
              {normalizedValues.map((value, index) => (
                <div className="keypain-layout" key={index}>
                  {(
                    isSidePanelOpen
                      ? value.title?.is_detailed
                      : value.title?.extra_3
                  ) ? (
                    <div
                      className="badge"
                      style={{
                        backgroundColor: value.title?.badge_color
                          ? value.title?.badge_color
                          : "#bae6fd",
                      }}
                    >
                      <RxCheckbox className="checkbox-icon" size="18px" />
                      {value.title?.value}
                    </div>
                  ) : null}

                  {value.link.extra_3 && value.link ? (
                    <a
                      href={value.link?.value}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <HiExternalLink className="link" />
                    </a>
                  ) : null}

                  {(
                    isSidePanelOpen
                      ? value.solution?.is_detailed ||
                        value.case_study?.is_detailed
                      : value.solution?.extra_3 || value.case_study?.extra_3
                  ) ? (
                    <div className="points">
                      <ul className="no-bullets">
                        <li>
                          {value.solution?.value || value.case_study?.value}
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div>
        {shouldCollapse ? (
          <div className="card-collapse">
            {normalizedValues.map((value, index) => {
              const titleEnabled = isSidePanelOpen
                ? value.title?.is_detailed
                  ? true
                  : false
                : value.title?.[cardType];

              return titleEnabled ? (
                <div
                  key={index}
                  className="badge"
                  style={{
                    backgroundColor: value.title?.badge_color
                      ? value.title?.badge_color
                      : "#bae6fd",
                  }}
                >
                  {value.title?.value}
                </div>
              ) : null;
            })}
          </div>
        ) : null}

        {!shouldCollapse ? (
          <div>
            {normalizedValues.map((value, index) => (
              <div className="keypain-layout" key={index}>
                {(
                  isSidePanelOpen
                    ? value.title?.is_detailed
                    : value.title?.[cardType]
                ) ? (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: value.title?.badge_color
                        ? value.title?.badge_color
                        : "#bae6fd",
                    }}
                  >
                    <RxCheckbox className="checkbox-icon" size="18px" />
                    {value.title?.value}
                  </div>
                ) : null}

                {value.link ? (
                  <a href={value.link?.value} target="_blank" rel="noreferrer">
                    <HiExternalLink className="link" />
                  </a>
                ) : null}

                {(
                  isSidePanelOpen
                    ? value.solution?.is_detailed ||
                      value.case_study?.is_detailed
                    : value.solution?.[cardType] || value.case_study?.[cardType]
                ) ? (
                  <div className="points">
                    <ul className="no-bullets">
                      <li>
                        {value.solution?.value || value.case_study?.value}
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  } catch (error) {
    return <Alert severity="error"> Error Processing card format 25</Alert>;
  }
};

export default CardFormat25;
