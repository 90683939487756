import React from "react";
import "./Company.css";
import logo from "../assets/logo.png";
import { LuGlobe } from "react-icons/lu";
import { ImLinkedin } from "react-icons/im";
import { CompanyProps } from "../../updated-interface";
import { Alert } from "@mui/material";

const Company: React.FC<CompanyProps> = ({
  type,
  value,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";
  try {
    if (call) {
      return (
        <div className="container-card">
          <header className="card-header">
            {/* Logo Rendering */}
            {/* {value.image?.extra_3 ? (
            <img src={value.image.value || logo} alt="logo" />
          ) : (
            <img src={logo} alt="logo" />
          )} */}
            <div>
              <div className="company-name-and-icons">
                {/* Title Rendering with isSidePanelOpen Check */}
                {isSidePanelOpen ? (
                  value.buyer?.is_detailed ? (
                    <p>{value.buyer.value}</p>
                  ) : null
                ) : value.buyer?.extra_3 ? (
                  <p>{value.buyer.value}</p>
                ) : null}
                <div className="contact-icon-buttons">
                  {value.globe?.value && value.globe?.extra_3 && (
                    <a
                      href={value.globe.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="globe"
                    >
                      <LuGlobe />
                    </a>
                  )}
                  {value.linkedin?.value && value.linkedin?.extra_3 && (
                    <a
                      href={value.linkedin?.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin"
                    >
                      <ImLinkedin />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </header>

          {!shouldCollapse ? (
            <div className="company-content">
              {isSidePanelOpen ? (
                <>
                  {value.quick_summary?.is_detailed ? (
                    <p className="about-company">{value.quick_summary.value}</p>
                  ) : null}
                  <div className="detail1">
                    {value.size?.is_detailed ? (
                      <p>
                        <span>Size</span> {value.size.value}
                      </p>
                    ) : null}
                    {value.deal_stage?.is_detailed ? (
                      <p>
                        <span>Deal Stage</span> {value.deal_stage.value}
                      </p>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  {value.quick_summary?.value &&
                    value.quick_summary?.extra_3 && (
                      <p className="about-company">
                        {value.quick_summary.value}
                      </p>
                    )}
                  <div className="detail1">
                    {value.size?.value && value.size?.extra_3 && (
                      <p>
                        <span>Size</span> {value.size.value}
                      </p>
                    )}
                    {value.deal_stage?.value && value.deal_stage?.extra_3 && (
                      <p>
                        <span>Deal Stage</span> {value.deal_stage.value}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="container-card">
        <header className="card-header">
          {/* Logo Rendering */}
          {/* {value.image?.[cardType] ? (
          <img src={value.image.value || logo} alt="logo" />
        ) : (
          <img src={logo} alt="logo" />
        )} */}
          <div>
            <div className="company-name-and-icons">
              {/* Title Rendering with isSidePanelOpen Check */}
              {isSidePanelOpen ? (
                value.buyer?.is_detailed ? (
                  <p>{value.buyer.value}</p>
                ) : null
              ) : value.buyer?.[cardType] ? (
                <p>{value.buyer.value}</p>
              ) : null}
              <div className="contact-icon-buttons">
                {value.globe?.value && value.globe?.[cardType] && (
                  <a
                    href={value.globe.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="globe"
                  >
                    <LuGlobe />
                  </a>
                )}
                {value.linkedin?.value && value.linkedin?.[cardType] && (
                  <a
                    href={value.linkedin?.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin"
                  >
                    <ImLinkedin />
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        {!shouldCollapse ? (
          <div className="company-content">
            {isSidePanelOpen ? (
              <>
                {value.quick_summary?.is_detailed ? (
                  <p className="about-company">{value.quick_summary.value}</p>
                ) : null}
                <div className="detail1">
                  {value.size?.is_detailed ? (
                    <p>
                      <span>Size</span> {value.size.value}
                    </p>
                  ) : null}
                  {value.deal_stage?.is_detailed ? (
                    <p>
                      <span>Deal Stage</span> {value.deal_stage.value}
                    </p>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                {value.quick_summary?.value &&
                  value.quick_summary?.[cardType] && (
                    <p className="about-company">{value.quick_summary.value}</p>
                  )}
                <div className="detail1">
                  {value.size?.value && value.size?.[cardType] && (
                    <p>
                      <span>Size</span> {value.size.value}
                    </p>
                  )}
                  {value.deal_stage?.value && value.deal_stage?.[cardType] && (
                    <p>
                      <span>Deal Stage</span> {value.deal_stage.value}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  } catch (error) {
    console.log(error);
    return <Alert severity="error"> Error Processing accounts card</Alert>;
  }
};

export default Company;
