import React, { useState, useEffect } from "react";
import BaseCard from "./components/BaseCard/BaseCard";
import People from "./components/PeopleCards/People";
import Company from "./components/Company/Company";
import Objections from "./components/Objections/Objections";
import CardFormat25 from "./components/CardFormats/CardFormat25";
import CardFormat21 from "./components/CardFormats/CardFormat21";
import Question from "./components/Questions/Questions";
import CardBant25 from "./components/CardBant/CardBant25";
import CardFormat22 from "./components/CardFormats/CardFormat22";
import Person from "./components/PeopleCards/Person";
import apiClient from "./apiService";
import { useLocation } from "react-router-dom";

interface DataType {
  card_id: number;
  is_plugin_enabled: boolean;
  is_as_enabled: boolean;
  row_type?: number;
  is_expand?: boolean;
  [key: string]: any;
}

const useFetchData = (accId: any, authToken?: string | null) => {
  const [data, setData] = useState<DataType | null>(null);
  const [error, setError] = useState<string | null>(null);
  let location = useLocation();
  function isCard25Detail(obj: any) {
    const detailKeys = [
      "solution",
      "impact",
      "case_study",
      "title",
      "link",
      "priority",
      "section_id",
    ];

    for (const key in obj) {
      if (!detailKeys.includes(key)) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);

      const domain = queryParams.get("domain");

      try {
        const response = await apiClient.get(
          `/accounts/${accId}/meeting_prep`,
          {
            params: domain ? { domain: domain } : {},
          }
        );
        setData(JSON.parse(response.data.prep_data));
      } catch (err) {
        setError(`Error fetching data: ${err}`);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      console.log("Data has been set:", data); // Data is available here
    }
  }, [data]);
  const extension: { order: number; content: React.ReactNode }[] = [];
  const rightSection: { order: number; content: React.ReactNode }[] = [];
  const section1: { order: number; content: React.ReactNode }[] = [];
  const section2: { order: number; content: React.ReactNode }[] = [];
  const section3: { order: number; content: React.ReactNode }[] = [];
  const section4: { order: number; content: React.ReactNode }[] = [];
  const section5: { order: number; content: React.ReactNode }[] = [];
  const section6: { order: number; content: React.ReactNode }[] = [];
  const section7: { order: number; content: React.ReactNode }[] = [];
  const expandSection: {
    id: string;
    title: string;
    content: React.ReactNode;
    order: number;
  }[] = [];
  const newSections: {
    id: string;
    title: string;
    content: React.ReactNode;
    order: number;
  }[] = [];
  const formatTitle = (title: string): string => {
    return title
      .replace(/_/g, " ")
      .replace(/\bai\b/gi, "AI")
      .replace(/\bmeddpicc\b/gi, "MEDDPICC")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  function showSideCard(cardName: string) {
    for (const key in data) {
      const card = data[key];
      if (key === cardName) {
        const cardFormat = card.card_format;
        switch (cardFormat) {
          case 29:
            return (
              <Company
                type="account_strategy"
                value={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 2:
            return (
              <Objections
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 25:
            const Component = isCard25Detail(card.value[0])
              ? CardFormat25
              : CardBant25;
            return (
              <Component
                type="account_strategy"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 21:
          case 14:
            return (
              <CardFormat21
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 18:
            return (
              <Question
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 22:
            return (
              <CardFormat22
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
          case 30:
            return (
              <People
                type="account_strategy"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            );
        }
      }
    }
    return <></>;
  }

  function generateCard(key: string, title: string, card: any, call?: boolean) {
    switch (card.card_format) {
      case 30:
        return {
          order: card.order_no,
          content: (
            <Person
              type="account_strategy"
              values={card.value}
              isCollapsed
              accId={accId}
              is_expand={card.is_expand}
              call={call}
            />
          ),
        };
      case 29:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <Company
                type="account_strategy"
                value={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
              />
            </BaseCard>
          ),
        };
      case 2:
      case 32:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <Objections
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
                cardFormat={card.card_format}
              />
            </BaseCard>
          ),
        };
      case 25:
        const Component = isCard25Detail(card.value[0])
          ? CardFormat25
          : CardBant25;
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <Component
                type="account_strategy"
                values={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
              />
            </BaseCard>
          ),
        };
      case 21:
      case 14:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <CardFormat21
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
              />
            </BaseCard>
          ),
        };
      case 18:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <Question
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
              />
            </BaseCard>
          ),
        };
      case 22:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
              notes_color={card.notes_color}
              text_color={card.text_color}
            >
              <CardFormat22
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            </BaseCard>
          ),
        };

      default:
        return {
          order: card.order_no,
          content: (
            <BaseCard
              type="account_strategy"
              call={call}
              key={key}
              title={title}
              button={card.extra_card_2}
              buttonName={card.button_1}
              collapse={card.is_collapse}
              sideCardName={card.button_1_card_mapping}
              sideCard={showSideCard(card.button_1_card_mapping)}
            >
              <Objections
                type="account_strategy"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
                call={call}
                cardFormat={0}
              />
            </BaseCard>
          ),
        };
    }
  }
  if (data) {
    for (const key in data) {
      const card = data[key];
      const formattedTitle = formatTitle(key);
      const content = generateCard(key, formattedTitle, card);
      if (content) {
        if (card.is_as_enabled) {
          if (
            key == "case_studies" ||
            key == "next_steps" ||
            key == "red_flags"
          ) {
            rightSection.push(content);
          } else if (card.row_type === 1) {
            if (card.section_id === 1) {
              section1.push(content);
            } else if (card.section_id === 2) {
              section2.push(content);
            } else if (card.section_id === 3) {
              section3.push(content);
            } else if (card.section_id === 4) {
              section4.push(content);
            } else if (card.section_id === 5) {
              section5.push(content);
            } else if (card.section_id === 6) {
              section6.push(content);
            } else if (card.section_id === 7) {
              section7.push(content);
            }
          } else if (card.row_type === 2) {
            rightSection.push(content);
          }
        }
        if (card.is_plugin_enabled) {
          extension.push(content);
        }
      }
      if (card.is_expand) {
        if (card.card_format === 30) {
          expandSection.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <People
                type="account_strategy"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        }
      }
      if (card.is_15min_enabled) {
        if (card.card_format === 2) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <Objections
                type="meeting_prep"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 25 && isCard25Detail(card.value[0])) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <CardFormat25
                type="meeting_prep"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 18) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <Question
                type="meeting_prep"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 21 || card.card_format === 14) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <CardFormat21
                type="meeting_prep"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 25 && !isCard25Detail(card.value[0])) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <CardBant25
                type="meeting_prep"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 30) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <People
                type="meeting_prep"
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 29) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <Company
                type="meeting_prep"
                value={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        } else if (card.card_format === 22) {
          newSections.push({
            id: key,
            title: formattedTitle,
            order: card.order_no,
            content: (
              <CardFormat22
                type="meeting_prep"
                badge_color={card.badge_color}
                text_color={card.text_color}
                indent_color={card.indent_color}
                suggestion_color={card.suggestion_color}
                notes_color={card.notes_color}
                extra_1={card.extra_1}
                extra_2={card.extra_2}
                extra_3={card.extra_3}
                extra_4={card.extra_4}
                values={card.value}
                isCollapsed
                isSidePanelOpen
              />
            ),
          });
        }
      }
    }
  }

  return {
    extension,
    rightSection,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    expandSection,
    newSections,
    generateCard,
  };
};
export default useFetchData;
