import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Sidebar from "./components/MainSidebar/Main_Sidebar";
import CompanyInfo from "./components/CompanyInfo/CompanyInfo";
import Meeting_prep from "./components/MeetingPrep/Meeting_prep";
import AccountOverview from "./components/AccountOverview/Account_Overview";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomePage from "./components/HomePage/HomePage";
import CallsList from "./components/CallTranscript/Call_Transcript";
import Extension from "./components/Extension/Extension";
import axios from "axios";
import Login from "./components/Login/Login";
import Onboarding from "./components/Onboarding";
import Chat from "./components/chat";
import Admin from "./components/Admin/Admin";
import apiClient from "./apiService";
import { constant } from "./constant";

const App = ({ selectedText = "" }) => {
  const navigate = useNavigate();

  // Redirect to /login on app startup

  const isChromeExtension =
    typeof window.chrome !== "undefined" &&
    typeof window.chrome.runtime !== "undefined";

  const location = useLocation();
  const [params] = useSearchParams();
  const [authToken, setAuth] = useState<string | null>(
    params.get("authToken") || null
  );

  // Set the initial activePage based on the current URL path
  const getInitialPage = () => {
    if (location.pathname.startsWith("/documents/meeting_prep")) {
      return "meeting_prep";
    }
    if (location.pathname.startsWith("/documents/account_overview")) {
      return "account_overview";
    }
    if (location.pathname.startsWith("/documents/call_transcript")) {
      return "call_transcripts";
    }
    return "account_overview";
  };

  const [activeTab, setActiveTab] = useState<string>(getInitialPage());
  const [isSubmitted, setIsSubmitted] = useState(true);
  const getInitialIcon = () => {
    if (location.pathname.startsWith("/documents")) {
      return "Accounts";
    }
    if (location.pathname.startsWith("/Admin")) {
      return "Admin";
    }
    return "Home";
  };
  const [activePage, setActivePage] = useState<string>(getInitialIcon());
  const [currentPage, setCurrentPage] = useState<string>("Home");
  const [accId, setAccId] = useState<string | null>(
    params.get("accId") || null
  );

  const [companyName, setCompanyName] = useState<string>(
    params.get("compName") || ""
  );
  const data = {
    company_name: companyName,
    address: "Detroit, Michigan",
    company_type: "Manufacturing",
    next_call: {
      title: "Product Demo",
      date: "Tuesday, September 3 at 2pm",
    },
  };

  useEffect(() => {
    axios
      .get(constant.authMeURL, {
        withCredentials: true,
      })
      .then((response) => {
        const token = response?.data?.[0]?.id_token;
        apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setAuth(token);
        if (!token) {
          navigate("/login");
        }
        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.

        const nameClaim = response?.data?.[0]?.user_claims?.find(
          (claim: { typ: string }) => claim.typ === "name"
        );
        const idClaim = response?.data?.[0]?.user_claims?.find(
          (claim: { typ: string }) =>
            claim.typ ===
            "http://schemas.microsoft.com/identity/claims/objectidentifier"
        );
        const name = nameClaim ? nameClaim.val : "Name not found";
        const id = idClaim ? idClaim.val : "id not found";
        const getDomainFromEmail = (email: string) => {
          const regex = /@([\w.-]+)/;
          const match = email.match(regex);
          if (match) {
            return match[1];
          } else {
            return "no email";
          }
        };
        pendo.initialize({
          visitor: {
            id: response?.data?.[0]?.user_id, // Required if user is logged in
            email: response?.data?.[0]?.user_id, // Recommended if using Pendo Feedback, or NPS Email
            full_name: name, // Recommended if using Pendo Feedback
            oid: id,
          },

          account: {
            id: getDomainFromEmail(response?.data?.[0]?.user_id), // Highly recommended, required if using Pendo Feedback
            name: name,
          },
        });
      })
      .catch(() => {
        navigate("/login");
      });
  }, []);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleSideBarIconChange = (icon: string) => {
    setActivePage(icon);
  };

  const [sidebarState, setSidebarState] = useState("collapsed");
  const handleSidebarToggle = (state: string) => {
    setSidebarState(state);
  };

  const isDesktop = useMediaQuery("(min-width:769px)");

  return (
    <div className="App">
      {isChromeExtension ? (
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                setAccId={setAccId}
                accId={accId}
                state={sidebarState}
                onActiveTab={setActiveTab}
                onIconChange={handleSideBarIconChange}
                setCompanyName={setCompanyName}
                selectedText={selectedText}
                isChromeExtension={isChromeExtension}
                authToken={authToken}
                onSubmit={setIsSubmitted}
              />
            }
          />
          <Route
            path="/documents/account_overview"
            element={<Extension accId={accId} authToken={authToken} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <>
          {location.pathname != "/login" && (
            <>
              <Header
                onPageChange={setCurrentPage}
                accId={accId}
                disableNavigation={isSubmitted}
                companyName={companyName}
              />
              {isDesktop && (
                <Sidebar
                  accId={accId}
                  onToggle={handleSidebarToggle}
                  onActiveTab={setActiveTab}
                  onIconChange={handleSideBarIconChange}
                  disableNavigation={isSubmitted}
                  companyName={companyName}
                />
              )}
            </>
          )}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/Onboarding"
              element={
                <Onboarding authToken={authToken} state={sidebarState} />
              }
            />
            <Route path="/Admin" element={<Admin state={sidebarState} />} />
            <Route
              path="/chat"
              element={<Chat state={sidebarState} authToken={authToken} />}
            />
            <Route
              path="/"
              element={
                <HomePage
                  setAccId={setAccId}
                  accId={accId}
                  state={sidebarState}
                  onActiveTab={setActiveTab}
                  onIconChange={handleSideBarIconChange}
                  setCompanyName={setCompanyName}
                  selectedText={selectedText}
                  isChromeExtension={isChromeExtension}
                  authToken={authToken}
                  onSubmit={setIsSubmitted}
                  companyName={companyName}
                />
              }
            />
            <Route
              path="/documents/account_overview"
              element={
                <>
                  <CompanyInfo
                    onIconChange={handleSideBarIconChange}
                    data={data}
                    activeTab={activeTab}
                    state={sidebarState}
                    onTabChange={handleTabChange}
                    accId={accId}
                    authToken={authToken}
                    companyName={companyName}
                  />
                  <AccountOverview state={sidebarState} />
                </>
              }
            />
            <Route
              path="/documents/meeting_prep"
              element={
                <>
                  <CompanyInfo
                    onIconChange={handleSideBarIconChange}
                    data={data}
                    activeTab={activeTab}
                    state={sidebarState}
                    onTabChange={handleTabChange}
                    accId={accId}
                    authToken={authToken}
                    companyName={companyName}
                  />
                  <Meeting_prep state={sidebarState} />
                </>
              }
            />
            <Route
              path="/documents/call_transcript"
              element={
                <>
                  <CompanyInfo
                    onIconChange={handleSideBarIconChange}
                    data={data}
                    activeTab={activeTab}
                    state={sidebarState}
                    onTabChange={handleTabChange}
                    accId={accId}
                    authToken={authToken}
                    companyName={companyName}
                  />
                  <CallsList state={sidebarState} />
                </>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
    </div>
  );
};

export default App;
