import React from "react";
import "./Admin.css";
import { constant } from "../../constant";

interface Prop {
  state: string;
}

const Admin: React.FC<Prop> = ({ state }) => {
  function handleRedirect() {
    const url = constant.OnBoarding;
    window.open(url, "_blank");
  }

  return (
    <div>
      <button
        style={{
          left: state === "collapsed" ? "100px" : "300px",
        }}
        className="adminButton"
        onClick={handleRedirect}
      >
        Connect to calendar
      </button>
    </div>
  );
};

export default Admin;
