import React from "react";
import "./CardFormat22.css";
import { Card22Props } from "../../updated-interface";

interface Field {
  value?: string | string[];
  is_detailed?: boolean;
  is_as_enabled?: boolean;
  is_mp_enabled?: boolean;
}

const CardFormat22: React.FC<Card22Props> = ({
  type,
  badge_color,
  text_color,
  indent_color,
  suggestion_color,
  notes_color,
  extra_1,
  extra_2,
  extra_3,
  extra_4,
  values,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";

  const isFieldEnabled = (field: Field | undefined): boolean => {
    if (!field) return false;

    if (isSidePanelOpen) {
      return !!field.is_detailed;
    }
    return !!field[cardType];
  };

  const formatColor = (color: string) => {
    if (color == null) {
      return "";
    }
    return color.startsWith("#") ? color : `#${color || "#bae6fd"}`;
  };
  const indentColor = formatColor(indent_color);
  if (call) {
    return (
      <div className="card-22">
        {!shouldCollapse ? (
          <div>
            {values.description?.extra_3 ? (
              <div className="title-22">
                <p
                  style={{
                    color: formatColor(text_color),
                  }}
                >
                  {values.description?.value}
                </p>
              </div>
            ) : null}

            {values.reasons?.extra_3 ? (
              <div className="points-22">
                {values.reasons &&
                  values.reasons?.value.map((reason, index) => (
                    <ol
                      key={index}
                      style={
                        {
                          color: formatColor(text_color),
                          "--indent-color": indentColor,
                        } as React.CSSProperties
                      }
                    >
                      {reason}
                    </ol>
                  ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className="card-22">
      {!shouldCollapse ? (
        <div>
          {isFieldEnabled(values.description) ? (
            <div className="title-22">
              <p
                style={{
                  color: formatColor(text_color),
                }}
              >
                {values.description?.value}
              </p>
            </div>
          ) : null}

          {isFieldEnabled(values.reasons) ? (
            <div className="points-22">
              {values.reasons &&
                values.reasons?.value.map((reason, index) => (
                  <ol
                    key={index}
                    style={
                      {
                        color: formatColor(text_color),
                        "--indent-color": indentColor,
                      } as React.CSSProperties
                    }
                  >
                    {reason}
                  </ol>
                ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default CardFormat22;
