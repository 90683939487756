import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { constant } from "../constant";

interface AdminProps {
  authToken: string | null;
  state: string;
}

const Onboarding: React.FC<AdminProps> = ({ authToken, state }) => {
  const isDesktop = useMediaQuery("(min-width:769px)");
  const url = constant.onBoardingURL + "?token=" + authToken;

  const width = isDesktop
    ? state === "collapsed"
      ? "calc(100% - 74px)"
      : "calc(100% - 269px)"
    : "100%";

  return (
    <Box display="flex" justifyContent="end">
      <iframe
        src={url}
        title={"Admin"}
        style={{
          width: width,
          marginTop: isDesktop ? "40px" : "64px",
          border: "none",
          height: isDesktop ? "calc(100vh - 40px)" : "calc(100vh - 64px)",
        }}
        sandbox="allow-same-origin allow-scripts"
      />
    </Box>
  );
};

export default Onboarding;
