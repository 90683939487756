import React, { useEffect, useState } from "react";
import "./Call_Transcript.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  createTheme,
  ThemeProvider,
  Alert,
} from "@mui/material";
import "../BaseCard/BaseCard.css";
import { IoIosClose } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import useFetchData from "../../parsedata";
import apiClient from "../../apiService";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
});

type Props = {
  state: string;
};

const CallsList: React.FC<Props> = ({ state }) => {
  const [data, setData] = useState<any | undefined>(undefined);
  const [feedbackArray, setFeedbackArray] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  // Parse and clean company name
  const CompanyName = searchParams.get("compName");
  const cleanCompanyName = CompanyName?.replace(/\..*$/, "");
  const accId = searchParams.get("accId");
  const authToken = searchParams.get("authToken");
  const { generateCard } = useFetchData(accId, authToken);
  let location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const domain = queryParams.get("domain");
        const response = await apiClient.get(`/accounts/${accId}/calls`, {
          params: domain ? { domain: domain } : {},
        });
        setData(response.data);

        const feedbackPromises = response.data.map(async (call: any) => {
          const feedbackResponse = await apiClient.get(
            `/accounts/${accId}/call/${call._id}/feedback`,
            {
              params: domain ? { domain: domain } : {},
            }
          );
          console.log(feedbackResponse.data);
          return feedbackResponse.data.feedback;
        });

        const feedbackResults = await Promise.all(feedbackPromises);
        setFeedbackArray(feedbackResults);
      } catch (err) {
        setError(`Error fetching data: ${err}`);
        console.log(err);
      }
    };

    fetchData();
  }, [accId]);

  useEffect(() => {
    if (data && feedbackArray.length) {
      console.log("Data:", data);
      console.log("feedbach array" + feedbackArray);
    }
  }, [data, feedbackArray]);

  const formatTitle = (title: string): string => {
    return title
      .replace(/_/g, " ")
      .replace(/\bai\b/gi, "AI")
      .replace(/\bmeddpicc\b/gi, "MEDDPICC")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function cardFormat(data: any) {
    try {
      const cards = [];

      if (data) {
        for (const key in data) {
          const card = data[key];
          if (card.extra_card_3) {
            const formattedTitle = formatTitle(key);
            const context = generateCard(key, formattedTitle, card, true);
            if (context) {
              cards.push({ order: context.order, content: context.content });
            }
          }
        }

        cards.sort((a, b) => a.order - b.order);

        return cards.map((card, index) => (
          <div key={index}>{card.content}</div>
        ));
      }

      return <Alert severity="error">Error processing feedback data.</Alert>;
    } catch (error) {
      console.error("Error processing feedback:", error);
      return <Alert severity="error">Error processing feedback data.</Alert>;
    }
  }
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedRecording, setSelectedRecording] = useState<string | null>(
    null
  );
  const toggleSidePanel = (
    e: React.MouseEvent,
    recordingLink: string | null = null
  ) => {
    e.stopPropagation();
    setSelectedRecording(recordingLink);
    setIsSidePanelOpen((prev) => !prev);
  };
  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedRecording(null);
    setIsSidePanelOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`main-screen${state === "collapsed" ? "" : "-expanded"}`}>
        <div className="call-list-container">
          {data?.map((call: any, index: any) => (
            <Accordion key={call.call_id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${call.call_id}-content`}
                id={`panel${call.call_id}-header`}
              >
                <Typography>{`Call on ${new Date(
                  call.created_at
                ).toLocaleDateString()} (Duration: ${
                  call.duration
                })`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="call-details">
                  <div>
                    <button className="side-panel-toggle">
                      <a
                        href={call.metadata?.transcript_path}
                        target="_blank"
                        className="transcript"
                      >
                        Link to Transcript
                      </a>
                    </button>
                  </div>
                  {call.recordings?.length > 0 ? (
                    <div className="recordings-list">
                      {call.recordings.map((recording: any, index: any) => (
                        <button
                          key={index}
                          className="side-panel-toggle"
                          onClick={(e) => toggleSidePanel(e, recording.url)}
                        >
                          {`Link to Recording${index + 1}`}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <Alert severity="error">
                      {call.recordings
                        ? "No recordings available."
                        : "Error: Recordings data is not in the expected format."}
                    </Alert>
                  )}
                </Typography>

                <Typography className="call-analysis">
                  <strong>Analysis:</strong>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      columnGap: "12px",
                      width: window.innerWidth < 768 ? "100%" : "fit-content",
                      marginTop: "15px",
                    }}
                    className="call-cards"
                  >
                    {feedbackArray[index]
                      ? cardFormat(JSON.parse(feedbackArray[index]))
                      : "Feedback not available"}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        {isSidePanelOpen && selectedRecording && (
          <div className="side-panel">
            <div className="side-panel-header">
              <div className="panel_title">
                <h4>{cleanCompanyName}</h4>
              </div>
              <button className="side-panel-close" onClick={onClose}>
                <IoIosClose />
              </button>
            </div>
            <div className="Recommend">
              <iframe
                src={selectedRecording}
                title="Recording"
                style={{
                  width: "100%",
                  height: "80vh",
                  border: "none",
                  display: "block",
                }}
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default CallsList;
