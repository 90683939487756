import React from "react";
import "./Login.css";
import google from "../../assets/google.png";
import microsoft from "../../assets/microsoft.png";
import logo from "../../assets/Sparrow logos/Sparrow_logo_transparent_bg.png";

import { constant } from "../../constant";

const Login: React.FC = () => {
  const microsoftLogin = () => {
    window.location.href = constant.microsoftLoginURL;
  };
  const googleLogin = () => {
    window.location.href = constant.googleLoginURL;
  };
  return (
    <div className="screen">
      <img src={logo} alt="Sparrow Logo" className="logo-sparrow" />
      <div className="out-box">
        <h3>Log in to Sparrow</h3>
        <div className="inner-container">
          <div className="inner-box" onClick={googleLogin}>
            <img src={google}></img>Continue with Google
          </div>
          <div className="inner-box" onClick={microsoftLogin}>
            <img src={microsoft}></img>Continue with Microsoft
          </div>
          <div className="terms">
            By continuing, you agree to Sparrow’s
            <a href="#">Terms of Service</a> and <br></br>
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
