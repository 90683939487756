const thingsToRemember = [
  "Always tie our features back to the specific challenges and goals of the safety manager, focusing on creating a proactive safety culture with increased worker participation and actionable insights.",
  "Emphasize our winning capabilities: Dashboards & Analytics, Ease of use, QR Code Incident Reporting, Flexible User Permissions, Contractor Safety, Customer Success Support, Safety Index, and Configurability.",
  "Remember that while Bill is your primary contact, the final decision may involve other stakeholders like the COO or IT department. Be prepared to address their concerns as well.",
];

const constant = {
  extensionURL:
    "https://app.mysparrow.io/documents/account_overview?accId=${accId}",
  logoutURL: `https://${process.env.REACT_APP_API_DOMAIN}/.auth/logout`,
  baseURL: "https://57.154.250.192:8000",
  authMeURL: `https://${process.env.REACT_APP_API_DOMAIN}/.auth/me`,
  authRefreshURL: `https://${process.env.REACT_APP_API_DOMAIN}/.auth/refresh`,
  microsoftLoginURL: ` https://${process.env.REACT_APP_API_DOMAIN}/.auth/login/aad`,
  googleLoginURL: `https://${process.env.REACT_APP_API_DOMAIN}/.auth/login/google`,
  onBoardingURL: "https://57.154.250.192:8555/",
  OnBoarding:"http://onboarding.mysparrow.io/",
  chatURL: "https://57.154.250.192:8556/",
};

const sectionTitles = [
  "At a Glance",
  "People & Organization",
  "Qualification",
  "Buying Process",
  "Recommendations for AE",
  "Pains & Needs",
  "Assessment",
];

export { thingsToRemember, constant, sectionTitles };
