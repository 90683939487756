import React from "react";
import "./People.css";
import avatar from "../../assets/avatar.png";
import { FaLinkedin } from "react-icons/fa";
import { PeoplesProps } from "../../updated-interface";

const People: React.FC<PeoplesProps> = ({
  type,
  values,
  isCollapsed,
  isSidePanelOpen,
}) => {
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";

  // Normalize `values` to always be an array
  const normalizedValues = Array.isArray(values) ? values : [values];

  return (
    <div>
      {normalizedValues.map((value, index) => (
        <div key={index}>
          {value?.type?.value === "buyer" ? (
            <Peoples
              name={
                isSidePanelOpen
                  ? value.name?.is_detailed
                    ? value.name?.value
                    : undefined
                  : value.name?.[cardType]
                  ? value.name?.value
                  : undefined
              }
              role={
                isSidePanelOpen
                  ? value.role?.is_detailed
                    ? value.role?.value
                    : undefined
                  : value.role?.[cardType]
                  ? value.role?.value
                  : undefined
              }
              persona={
                isSidePanelOpen
                  ? value.persona?.is_detailed
                    ? value.persona?.value
                    : undefined
                  : value.persona?.[cardType]
                  ? value.persona?.value
                  : undefined
              }
              company={
                isSidePanelOpen
                  ? value.company?.is_detailed
                    ? value.company?.value
                    : undefined
                  : value.company?.[cardType]
                  ? value.company?.value
                  : undefined
              }
              industry={
                isSidePanelOpen
                  ? value.industry?.is_detailed
                    ? value.industry?.value
                    : undefined
                  : value.industry?.[cardType]
                  ? value.industry?.value
                  : undefined
              }
              linkedin={
                isSidePanelOpen
                  ? value.linkedin?.is_detailed
                    ? value.linkedin?.value
                    : undefined
                  : value.linkedin?.[cardType]
                  ? value.linkedin?.value
                  : undefined
              }
              description={
                isSidePanelOpen
                  ? value.description?.is_detailed
                    ? value.description?.value
                    : undefined
                  : value.description?.[cardType]
                  ? value.description?.value
                  : undefined
              }
              isCollapsed={isCollapsed}
              image={
                isSidePanelOpen
                  ? value.image?.is_detailed
                    ? value.image?.value
                    : undefined
                  : value.image?.[cardType]
                  ? value.image?.value
                  : undefined
              }
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

interface DetailsProps {
  name?: string;
  role?: string;
  persona?: string;
  company?: string;
  industry?: string;
  isCollapsed?: boolean;
  linkedin?: string;
  description?: string;
  image?: string;
}
const Peoples: React.FC<DetailsProps> = ({
  name,
  role,
  persona,
  company,
  industry,
  linkedin,
  description,
  image,
  isCollapsed,
}) => {
  return (
    <div>
      <div className="person">
        <img src={image || avatar} alt="user" className="avatar"></img>

        {name ? (
          <span className="person-name">
            <text>{name}</text>
          </span>
        ) : null}
        {role ? (
          <span className="person-position">
            <strong>{role}</strong>
          </span>
        ) : null}
        {linkedin && (
          <a
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            <FaLinkedin className="person-logo" />
          </a>
        )}
      </div>
      <div className="person-details">
        <p>
          {persona ? (
            <>
              {persona} <span className="dot"></span>
            </>
          ) : null}
          {company ? (
            <>
              {company} <span className="dot"></span>
            </>
          ) : null}
          {industry ? industry : null}
        </p>
      </div>
      {!isCollapsed ? (
        description ? (
          <div className="person-info">
            {description && (
              <div>
                <span>
                  <li>{description}</li>
                </span>
              </div>
            )}
          </div>
        ) : null
      ) : null}
    </div>
  );
};

export default People;
