import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AppWrapper = () => {
  const [selectedText, setSelectedText] = useState("");

  console.log("selectedText", selectedText);

  useEffect(() => {
    const fetchSelectedText = async () => {
      const [tab] =
        (await chrome?.tabs?.query({
          active: true,
          currentWindow: true,
        })) || [];
      if (tab?.id) {
        chrome.tabs.sendMessage(
          tab.id,
          { action: "getSelectedText" },
          (response) => {
            if (response && response.text) {
              setSelectedText(response.text);
            }
          }
        );
      } else {
        console.error("No active tab found.");
      }
    };

    fetchSelectedText();
  }, []);

  return <App selectedText={selectedText} />;
};

root.render(
  <BrowserRouter>
    <AppWrapper />
  </BrowserRouter>
);

reportWebVitals();
