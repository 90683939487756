import React, { useEffect } from "react";
import "./Account_Overview.css";
import ProgressBar from "./ProgressBar/ProgressBar";
import useFetchData from "../../parsedata";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { sectionTitles } from "../../constant";

type Props = {
  state: string;
};

const AccountOverview: React.FC<Props> = ({ state }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Parse accId from query parameters
  const accId = searchParams.get("accId");
  const authToken = searchParams.get("authToken");

  useEffect(() => {
    if (accId === "null" || accId === null) {
      navigate("/");
    }
  }, [accId]);

  const {
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    rightSection,
  } = useFetchData(accId, authToken);

  // Check screen width (768px or below)
  const isMobileView = useMediaQuery("(max-width:768px)");

  const sections = [
    section1,
    section2,
    section3,
    section6,
    section7,
    section4,
    section5,
  ];
  const sortedSections = [...rightSection].sort((a, b) => a.order - b.order);
  return (
    <div className={`main-screen${state === "collapsed" ? "" : "-expanded"}`}>
      {/* <ProgressBar /> */}
      <div className="cards-components">
        <div className="left-column">
          {sections.map((section, index) => {
            const sorted = section?.length
              ? [...section].sort((a, b) => a.order - b.order)
              : [];

            return (
              <div key={index} className="sections">
                <div className="inside-section">
                  {sorted.length ? (
                    <>
                      <h3>{sectionTitles[index]}</h3>
                      <div>{sorted.map((sec) => sec.content)}</div>
                    </>
                  ) : null}
                  {isMobileView && index === 1 && (
                    <div className="right-merge">
                      {sortedSections.map((rightSec) => rightSec.content)}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {!isMobileView && (
          <div className="right-column">
            {sortedSections.map((rightSec) => rightSec.content)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountOverview;
