import React from "react";
import "./Questions.css";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Card18Props } from "../../updated-interface";

const Question: React.FC<Card18Props> = ({
  type,
  badge_color,
  text_color,
  indent_color,
  suggestion_color,
  notes_color,
  extra_1,
  extra_2,
  extra_3,
  extra_4,
  values,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";
  const isFieldEnabled = (field: any): boolean => {
    if (!field) return false;
    if (isSidePanelOpen) {
      return !!field.is_detailed;
    }
    return !!field[cardType];
  };

  const formatColor = (color: string) => {
    if (color == null) {
      return "";
    }
    return color.startsWith("#") ? color : `#${color || ""}`;
  };

  if (call) {
    return (
      <div>
        {!shouldCollapse ? (
          <div>
            {values.map((value, index) => {
              const backgroundColor = formatColor(badge_color);

              const textColor = formatColor(text_color);

              const indentColor = formatColor(indent_color);

              return (
                <div className="question" key={index}>
                  {
                    //   value.question?.extra_3 ||
                    // value.point?.extra_3 ||
                    //   value.expectation?.extra_3 --- Temp
                    true ? (
                      <QuestionPoints
                        challenge={value.value ?? value.question?.value}
                        title={value.title?.value ?? ""}
                        isSidePanelOpen={isSidePanelOpen}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        indentColor={indentColor}
                      />
                    ) : null
                  }

                  {
                    //   value.reason?.extra_3 ||
                    // value.importance?.extra_3 ||
                    // value.preparation?.extra_3 ||
                    //     value.cross_question?.extra_3  --- temp
                    true ? (
                      <FollowUpQuestion
                        points={value.cross_question?.value}
                        isSidePanelOpen={isSidePanelOpen}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        indentColor={indentColor}
                      />
                    ) : null
                  }
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div>
      {!shouldCollapse ? (
        <div>
          {values.map((value, index) => {
            const backgroundColor = formatColor(badge_color);

            const textColor = formatColor(text_color);

            const indentColor = formatColor(indent_color);

            return (
              <div className="question" key={index}>
                {isFieldEnabled(value.question) ||
                isFieldEnabled(value.point) ||
                isFieldEnabled(value.expectation) ? (
                  <QuestionPoints
                    challenge={
                      value.question?.value ??
                      value.point?.value ??
                      value.expectation?.value
                    }
                    title={value.title?.value ?? ""}
                    isSidePanelOpen={isSidePanelOpen}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    indentColor={indentColor}
                  />
                ) : null}
                {isFieldEnabled(value.reason) ||
                isFieldEnabled(value.importance) ||
                isFieldEnabled(value.preparation) ? (
                  <FollowUpQuestion
                    points={
                      value.reason?.value ??
                      value.importance?.value ??
                      value.preparation?.value
                    }
                    isSidePanelOpen={isSidePanelOpen}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    indentColor={indentColor}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

interface QuestionPointsProps {
  challenge?: string;
  title: string;
  isSidePanelOpen: boolean;
  backgroundColor?: string;
  textColor?: string;
  indentColor?: string;
}

const QuestionPoints: React.FC<QuestionPointsProps> = ({
  challenge,
  title,
  isSidePanelOpen,
  backgroundColor,
  textColor,
  indentColor,
}) => {
  return (
    <div
      className="question-point"
      style={
        {
          "--indent-color": indentColor,
        } as React.CSSProperties
      }
    >
      <span className="questionSymbol">
        <AiOutlineQuestionCircle color="rgba(194, 65, 12, 1)" size="20px" />
      </span>
      <p style={{ color: textColor }}>
        {title && <span className="challenges">{title}: </span>}
        {challenge}
      </p>
    </div>
  );
};

interface FollowUpQuestionProps {
  points?: string;
  isSidePanelOpen: boolean;
  backgroundColor?: string;
  textColor?: string;
  indentColor?: string;
}

const FollowUpQuestion: React.FC<FollowUpQuestionProps> = ({
  points,
  isSidePanelOpen,
  backgroundColor,
  textColor,
  indentColor,
}) => {
  return (
    <div
      className="followup"
      style={
        {
          "--indent-color": indentColor,
        } as React.CSSProperties
      }
    >
      <p style={{ color: textColor }}>{points}</p>
    </div>
  );
};

export default Question;
