import React from "react";
import "./Objections.css";
import "../PainPoint/PainPoint.css";
import { Card2Props } from "../../updated-interface";
import { Alert } from "@mui/material";
import Note from "../Note/Note";

const Objections: React.FC<Card2Props> = ({
  type,
  values,
  badge_color,
  text_color,
  indent_color,
  suggestion_color,
  notes_color,
  extra_1,
  extra_2,
  extra_3,
  extra_4,
  isCollapsed,
  isSidePanelOpen,
  call,
  cardFormat,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";

  const isValueEnabled = (value: any, field: string, detailed: boolean) =>
    detailed ? value?.is_detailed : value?.[field];
  const formatColor = (color: string) => {
    if (color == null) {
      return "";
    }
    return color.startsWith("#") ? color : `#${color || "#bae6fd"}`;
  };

  const normalizedValues = Array.isArray(values) ? values : [values];
  const formatTitle = (title: string): string => {
    return title
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  try {
    if (call) {
      return (
        <div className="objections">
          {shouldCollapse && (
            <div className="card-collapse">
              {normalizedValues.map((value) => {
                return (
                  <>
                    {Object.keys(value).includes("buyer_engagement") ||
                    cardFormat === 32
                      ? Object.keys(value).map(
                          (key) =>
                            value[key]?.element_name &&
                            // value[key]?.extra_3 &&   - temp
                            (value[key]?.explanation?.value ||
                              value[key]?.value != "") && (
                              <div
                                className="badge"
                                style={{
                                  backgroundColor: formatColor(badge_color),
                                  color: formatColor(text_color),
                                }}
                              >
                                {formatTitle(value[key]?.element_name || "")}
                              </div>
                            )
                        )
                      : (value.keywords?.extra_3 || value.title?.extra_3) &&
                        (value.keywords?.value?.[0] || value.title?.value) && (
                          <div
                            className="badge"
                            style={{
                              backgroundColor: formatColor(badge_color),
                              color: formatColor(text_color),
                            }}
                          >
                            {formatTitle(
                              value.keywords?.value?.[0] ||
                                value.title?.value ||
                                ""
                            )}
                          </div>
                        )}
                  </>
                );
              })}
            </div>
          )}

          {/* Expanded state rendering */}
          {!shouldCollapse && (
            <div>
              {normalizedValues.map((value, index) => (
                //start of card
                <div className="objection-layout" key={index}>
                  {Object.keys(value).includes("buyer_engagement") ||
                  cardFormat === 32 ? (
                    <>
                      {Object.keys(value).map((key) => {
                        return (
                          <>
                            {
                              // value[key]?.extra_3 && -temp
                              (value[key]?.explanation?.value ||
                                value[key]?.value) && (
                                <div
                                  className="badge"
                                  style={{
                                    backgroundColor: formatColor(badge_color),
                                    color: formatColor(text_color),
                                  }}
                                >
                                  {formatTitle(value[key]?.element_name)}
                                </div>
                              )
                            }
                            <div className="objection-points">
                              <ul>
                                {/* {!value[key]?.extra_3 && ( temp */}
                                <>
                                  {Array.isArray(value[key]?.value) ? (
                                    <>
                                      {value[key].value.map(
                                        (item: string, idx: number) => (
                                          <li
                                            key={idx}
                                            style={{
                                              color: formatColor(text_color),
                                            }}
                                          >
                                            {item}
                                          </li>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {(value[key]?.value ||
                                        value[key]?.explanation?.value) && (
                                        <li
                                          style={{
                                            color: formatColor(text_color),
                                          }}
                                        >
                                          {value[key]?.value ||
                                            value[key]?.explanation?.value}
                                        </li>
                                      )}
                                      {value[key]?.rating.value && (
                                        <li
                                          style={{
                                            color: formatColor(text_color),
                                          }}
                                        >
                                          {value[key]?.rating?.value}
                                        </li>
                                      )}
                                    </>
                                  )}
                                </>
                              </ul>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {(value.keywords?.extra_3 ||
                        value.title?.extra_3 ||
                        value.buyer_engagement?.extra_3) &&
                        (value.keywords?.value?.[0] ||
                          value.title?.value ||
                          value.buyer_engagement?.element_name) && (
                          <div
                            className="badge"
                            style={{
                              backgroundColor: formatColor(badge_color),
                              color: formatColor(text_color),
                            }}
                          >
                            {formatTitle(
                              value.keywords?.value?.[0] ||
                                value.title?.value ||
                                value.buyer_engagement?.element_name ||
                                ""
                            )}
                          </div>
                        )}
                      <div className="objection-points">
                        <ul>
                          {value.details?.value ? (
                            <>
                              {value.details?.extra_3 &&
                                value.details?.value && (
                                  <li
                                    style={{
                                      color: formatColor(text_color),
                                    }}
                                  >
                                    {value.details?.value}
                                  </li>
                                )}
                            </>
                          ) : (
                            <>
                              {(value.description?.extra_3 ||
                                value.statement?.extra_3 ||
                                value.solution_offered?.extra_3 ||
                                value.buyer_engagement?.extra_3) &&
                                (value.description?.value ||
                                  value.statement?.value ||
                                  value.solution_offered?.value ||
                                  value.buyer_engagement?.explanation
                                    ?.value) && (
                                  <li
                                    style={{
                                      color: formatColor(text_color),
                                    }}
                                  >
                                    {value.description?.value ||
                                      value.statement?.value ||
                                      value.solution_offered?.value ||
                                      value.buyer_engagement?.explanation
                                        ?.value}
                                  </li>
                                )}
                              {value.analysis?.extra_3 &&
                                value.analysis?.value && (
                                  <li
                                    style={{
                                      color: formatColor(text_color),
                                    }}
                                  >
                                    {value.analysis?.value}
                                  </li>
                                )}
                              {value.improved_solution?.extra_3 &&
                                value.improved_solution?.value && (
                                  <li
                                    style={{
                                      color: formatColor(text_color),
                                    }}
                                  >
                                    {value.improved_solution?.value}
                                  </li>
                                )}
                              {value.buyer_engagement?.extra_3 &&
                                value.buyer_engagement?.rating?.value && (
                                  <li
                                    style={{
                                      color: formatColor(text_color),
                                    }}
                                  >
                                    {value.buyer_engagement?.rating?.value}
                                  </li>
                                )}
                            </>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
                //end of card format
              ))}
            </div>
          )}
        </div>
      );
    }
    if (cardFormat === 32 || cardFormat === 0) {
      return (
        <div className="objections">
          {isCollapsed && (
            <div className="card-collapse">
              {normalizedValues.map((value, index) => {
                return (
                  <div key={index}>
                    {Object.keys(value).map((key) =>
                      key != "section_id" &&
                      key != "title" &&
                      key != "keywords" &&
                      isValueEnabled(value[key], cardType, isSidePanelOpen) ? (
                        <div
                          key={key}
                          className="badge"
                          style={{
                            backgroundColor: formatColor(badge_color),
                            color: formatColor(text_color),
                          }}
                        >
                          {formatTitle(value[key].element_name)}
                        </div>
                      ) : null
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {/* Expanded state rendering */}
          {!isCollapsed && (
            <div>
              {normalizedValues.map((value, index) => (
                <div className="objection-layout" key={index}>
                  {Object.keys(value).map((key) =>
                    key != "section_id" &&
                    isValueEnabled(value[key], cardType, isSidePanelOpen) ? (
                      <>
                        <div
                          className="badge"
                          style={{
                            backgroundColor: formatColor(badge_color),
                            color: formatColor(text_color),
                          }}
                        >
                          {formatTitle(value[key].element_name)}
                        </div>
                        <div className="objection-points">
                          <ul>
                            {Array.isArray(value[key]?.value) ? (
                              <>
                                {value[key].value.map(
                                  (item: string, idx: number) => (
                                    <li
                                      key={idx}
                                      style={{
                                        color: formatColor(text_color),
                                      }}
                                    >
                                      {item}
                                    </li>
                                  )
                                )}
                              </>
                            ) : (
                              <li
                                style={{
                                  color: formatColor(text_color),
                                }}
                              >
                                {value[key]?.value}
                              </li>
                            )}
                          </ul>
                        </div>
                      </>
                    ) : null
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="objections">
        {shouldCollapse && (
          <div className="card-collapse">
            {normalizedValues.map((value, index) => {
              const keywordEnabled = isValueEnabled(
                value.keywords,
                cardType,
                isSidePanelOpen
              );
              const titleEnabled = isValueEnabled(
                value.title,
                cardType,
                isSidePanelOpen
              );

              return keywordEnabled || titleEnabled ? (
                <div
                  className="badge"
                  key={index}
                  style={{
                    backgroundColor: formatColor(badge_color),
                    color: formatColor(text_color),
                  }}
                >
                  {keywordEnabled
                    ? value.keywords?.value?.[0]
                    : value.title?.value}
                </div>
              ) : null;
            })}
          </div>
        )}

        {/* Expanded state rendering */}
        {!shouldCollapse && (
          <div>
            {normalizedValues.map((value, index) => (
              <div className="objection-layout" key={index}>
                {isValueEnabled(value.keywords, cardType, isSidePanelOpen) ||
                isValueEnabled(value.title, cardType, isSidePanelOpen) ? (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: formatColor(badge_color),
                      color: formatColor(text_color),
                    }}
                  >
                    {value.keywords?.[cardType]
                      ? value.keywords?.value?.[0]
                      : value.title?.value}
                  </div>
                ) : null}

                <div className="objection-points">
                  <ul>
                    {isValueEnabled(
                      value.description,
                      cardType,
                      isSidePanelOpen
                    ) ||
                    isValueEnabled(
                      value.statement,
                      cardType,
                      isSidePanelOpen
                    ) ? (
                      <li
                        style={{
                          color: formatColor(text_color),
                        }}
                      >
                        {value.description?.value || value.statement?.value}
                      </li>
                    ) : null}

                    {isValueEnabled(
                      value.solution,
                      cardType,
                      isSidePanelOpen
                    ) ||
                    isValueEnabled(
                      value.solution_available,
                      cardType,
                      isSidePanelOpen
                    ) ? (
                      <li
                        style={{
                          color: formatColor(text_color),
                        }}
                      >
                        {value.solution_available?.value ||
                          value.solution?.value}
                      </li>
                    ) : null}

                    {isValueEnabled(
                      value.impact,
                      cardType,
                      isSidePanelOpen
                    ) && (
                      <li
                        style={{
                          color: formatColor(text_color),
                        }}
                      >
                        {value.impact?.value}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  } catch (error) {
    return <Alert severity="error">Error Processing card format 2</Alert>;
  }
};

export default Objections;
