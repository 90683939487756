import React from "react";
import "./CardFormat21.css";
import { Card21Props } from "../../updated-interface";

const CardFormat21: React.FC<Card21Props> = ({
  type,
  values,
  badge_color,
  text_color,
  indent_color,
  suggestion_color,
  notes_color,
  extra_1,
  extra_2,
  extra_3,
  extra_4,
  isCollapsed,
  isSidePanelOpen,
  call,
}) => {
  const shouldCollapse = isSidePanelOpen ? false : isCollapsed;
  const cardType =
    type === "account_strategy" ? "is_as_enabled" : "is_mp_enabled";
  const formatColor = (color: string) => {
    if (color == null) return "";
    return color.startsWith("#") ? color : `#${color || "#bae6fd"}`;
  };

  // Function to render content for each section
  const renderSection = (section: any) => {
    const descriptionEnabled = isSidePanelOpen
      ? section.description?.is_detailed
      : section.description?.[cardType];

    const thingsToRememberEnabled = isSidePanelOpen
      ? section.things_to_remember?.is_detailed
      : section.things_to_remember?.[cardType];

    const nextStepsEnabled = isSidePanelOpen
      ? section.next_steps?.is_detailed
      : section.next_steps?.[cardType];

    const actionEnabled = isSidePanelOpen
      ? section.action?.is_detailed
      : section.action?.[cardType];

    if (
      descriptionEnabled ||
      thingsToRememberEnabled ||
      nextStepsEnabled ||
      actionEnabled
    ) {
      const indentColorFormatted = formatColor(indent_color);
      return (
        <span
          style={
            { "--indent-color": indentColorFormatted } as React.CSSProperties
          }
        >
          {descriptionEnabled && (
            <p style={{ color: formatColor(text_color) }}>
              {section.description?.value}
            </p>
          )}
          {thingsToRememberEnabled && (
            <p style={{ color: formatColor(text_color) }}>
              {section.things_to_remember?.value}
            </p>
          )}
          {nextStepsEnabled && (
            <p style={{ color: formatColor(text_color) }}>
              {section.next_steps?.value}
            </p>
          )}
          {actionEnabled && (
            <p style={{ color: formatColor(text_color) }}>
              {section.action?.value}
            </p>
          )}
        </span>
      );
    }
    return null;
  };
  if (call) {
    const renderSections = (section: any) => {
      if (
        section.description?.extra_3 ||
        section.things_to_remember?.extra_3 ||
        section.next_steps?.extra_3 ||
        section.action?.extra_3
      ) {
        const indentColorFormatted = formatColor(indent_color);
        return (
          <span
            style={
              { "--indent-color": indentColorFormatted } as React.CSSProperties
            }
          >
            {section.description?.value && (
              <p style={{ color: formatColor(text_color) }}>
                {section.description?.value}
              </p>
            )}
            {section.things_to_remember?.value && (
              <p style={{ color: formatColor(text_color) }}>
                {section.things_to_remember?.value}
              </p>
            )}
            {section.next_steps?.value && (
              <p style={{ color: formatColor(text_color) }}>
                {section.next_steps?.value}
              </p>
            )}
            {section.action?.value && (
              <p style={{ color: formatColor(text_color) }}>
                {section.action?.value}
              </p>
            )}
          </span>
        );
      }
      return null;
    };
    return (
      <div className="card-format-21">
        {!shouldCollapse && (
          <div className="user-remember9-content">
            {Array.isArray(values) ? (
              <span>
                {values.map((value, index) => (
                  <React.Fragment key={index}>
                    <ol>{renderSections(value)}</ol>
                  </React.Fragment>
                ))}
              </span>
            ) : (
              <ol>{renderSections(values)}</ol>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="card-format-21">
      {!shouldCollapse && (
        <div className="user-remember9-content">
          {Array.isArray(values) ? (
            <span>
              {values.map((value, index) => (
                <React.Fragment key={index}>
                  <ol>{renderSection(value)}</ol>
                </React.Fragment>
              ))}
            </span>
          ) : (
            <ol>{renderSection(values)}</ol>
          )}
        </div>
      )}
    </div>
  );
};

export default CardFormat21;
