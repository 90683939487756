import axios from "axios";
import { constant } from "./constant";
import { useNavigate } from "react-router-dom";

const apiClient = axios.create({
  baseURL: constant.baseURL,
});

let accessToken = "";
let tokenPromise: Promise<any> | null = null;

const fetchInitialToken = async () => {
  if (tokenPromise) return tokenPromise;

  tokenPromise = axios
    .get(constant.authMeURL, {
      withCredentials: true,
    })
    .then((response) => {
      const token = response?.data?.[0]?.id_token;
      setAuthToken(token);
      return token;
    })
    .catch((error) => {
      console.error("Failed to fetch initial token:", error);
      throw error;
    })
    .finally(() => {
      tokenPromise = null;
    });

  return tokenPromise;
};

const setAuthToken = (token: string) => {
  accessToken = token;
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const ensureAuthToken = async () => {
  if (!accessToken) {
    await fetchInitialToken();
  }
};

apiClient.interceptors.request.use(async (config) => {
  await ensureAuthToken();
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.response && error.response.status === 401) {
      const errorDetail = error.response.data.detail;
      if (errorDetail === "Token has expired.") {
        return axios
          .get(constant.authRefreshURL, {
            withCredentials: true,
          })
          .then((response) => {
            const newToken = response?.data?.[0]?.id_token;
            setAuthToken(newToken);
            error.config.headers["Authorization"] = `Bearer ${newToken}`;
            return axios.request(error.config);
          })
          .catch((refreshE) => {
            console.error("Refresh failed:", refreshE);
            return Promise.reject(error);
          });
      } else {
        console.error("Authentication error:", errorDetail);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
