import React, { useEffect, useRef, useState } from "react";
import "./Meeting_prep.css";
import BaseCard from "../BaseCard/BaseCard";
import useFetchData from "../../parsedata";
import { useSearchParams } from "react-router-dom";
type Props = {
  state: string;
};

const Meeting_prep: React.FC<Props> = ({ state }) => {
  const [searchParams] = useSearchParams();

  // Parse accId from query parameters
  const accId = searchParams.get("accId");
  const authToken = searchParams.get("authToken");
  const { newSections } = useFetchData(accId, authToken);
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});
  interface DataType {
    card_id: number;
    [key: string]: any;
  }

  // Scroll function
  const handleScroll = (id: string) => {
    sectionRefs.current[id]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const sortedSections = [...newSections].sort((a, b) => a.order - b.order);
  return (
    <div className={`main-screen${state === "collapsed" ? "" : "-expanded"}`}>
      <div className="body-header">
        <h3 className="meeting_prep_header">Meeting prep</h3>
        {/* <div className="row-meeting">
          <h4>Choose format</h4>
          <p>At a glance (1 min)</p>
          <button>Bite-sized (5 min)</button>
          <p>In-depth (15 min)</p>
        </div> */}
      </div>

      <div className="cards-component">
        <div className="sidebar">
          <h4>Jump to section</h4>
          <ul>
            {sortedSections.map((section) => (
              <li key={section.id}>
                <a
                  href={`#${section.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="cover-content">
          <div className="content">
            {sortedSections.map((section) => (
              <BaseCard
                key={section.id}
                title={section.title}
                ref={(el) => (sectionRefs.current[section.id] = el)}
              >
                {section.content}
              </BaseCard>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meeting_prep;
