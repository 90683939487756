import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/Sparrow logos/Sparrow_logo_transparent_bg.png";
import right from ".././data/Right Sidebar.png";
import "./Header.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { IoHomeOutline, IoCloseOutline } from "react-icons/io5";
import { FiBook, FiSearch } from "react-icons/fi";
import { LuPresentation } from "react-icons/lu";
import { CiChat1 } from "react-icons/ci";
import { IoMdMenu } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { constant } from "../../constant";
import { PiHandshake } from "react-icons/pi";

interface Props {
  activePage: string;
  onPageChange: (page: string) => void;
  accId: string | null;
  disableNavigation: boolean;
  companyName?: string;
}

const MobileHeader: React.FC<Props> = ({
  activePage,
  onPageChange,
  accId,
  disableNavigation,
  companyName,
}) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 314 }} role="presentation" p={2}>
      <Box display="flex" justifyContent="end">
        <IconButton onClick={toggleDrawer(false)} aria-label="close drawer">
          <IoCloseOutline />
        </IconButton>
      </Box>
      <List>
        {[
          { name: "Home", icon: <IoHomeOutline /> },
          { name: "Accounts", icon: <FiBook />, disabled: disableNavigation },
          { name: "Chat", icon: <CiChat1 /> },
          { name: "Admin", icon: <PiHandshake /> },
          { name: "Logout", icon: <IoLogOutOutline /> },
        ].map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => {
                if (!item.disabled) {
                  onPageChange(item.name);
                  toggleDrawer(false)();
                  if (item.name === "Home") {
                    navigate(`/`);
                  } else if (item.name === "Accounts") {
                    navigate(
                      `/documents/account_overview?accId=${accId}&compName=${companyName}`
                    );
                  } else if (item.name === "Chat") {
                    navigate("/chat");
                  } else if (item.name === "Admin") {
                    navigate(`/Admin`);
                  } else if (item.name === "Logout") {
                    window.location.href = constant.logoutURL;
                  }
                }
              }}
              className={`${
                activePage === item.name ? "active-menu-item" : ""
              } ${item.disabled ? "disabled-menu-item" : ""}`}
              disabled={item.disabled}
            >
              <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <header className="header-main">
      <IconButton onClick={toggleDrawer(true)} aria-label="open drawer">
        <IoMdMenu />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <div className="header-logo" onClick={() => navigate(`/`)}>
        <img src={logo} alt="Sparrow Logo" className="logo" />
      </div>
    </header>
  );
};

const DesktopHeader: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header className="header-main">
      <div className="header-logo" onClick={() => navigate(`/`)} role="button">
        <img src={logo} alt="Sparrow Logo" className="logo" />
      </div>
      <Button
        id="basic-button"
        sx={{ minWidth: "fit-content", padding: 0 }}
        onClick={handleClick}
      >
        <img src={right} alt="Right Sidebar" className="right-sidebar" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => (window.location.href = constant.logoutURL)}>
          Logout
        </MenuItem>
      </Menu>
    </header>
  );
};

const Header: React.FC<Omit<Props, "activePage">> = ({
  onPageChange,
  accId,
  disableNavigation,
  companyName,
}) => {
  const [currentActivePage, setCurrentActivePage] = useState("Home");
  const [disableNav, setDisableNav] = useState<boolean>(disableNavigation);
  const isDesktop = useMediaQuery("(min-width:769px)");
  const location = useLocation();
  const handlePageChange = (page: string) => {
    setCurrentActivePage(page);
    onPageChange(page);
  };

  const isPageReload = (): boolean => {
    const [navigation] = performance.getEntriesByType(
      "navigation"
    ) as PerformanceNavigationTiming[];
    if (navigation?.type === "reload") {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setCurrentActivePage("Home");
    } else if (
      path.includes("account_overview") ||
      path.includes("meeting_prep") ||
      path.includes("call_transcript")
    ) {
      if (isPageReload()) {
        setDisableNav(false);
      } else {
        setDisableNav(disableNavigation);
      }
      setCurrentActivePage("Accounts");
    } else if (path.includes("chat")) {
      setCurrentActivePage("Chat");
    } else if (path.includes("Admin")) {
      setCurrentActivePage("Admin");
    }
  }, [location]);

  return isDesktop ? (
    <DesktopHeader />
  ) : (
    <MobileHeader
      activePage={currentActivePage}
      onPageChange={handlePageChange}
      accId={accId}
      disableNavigation={disableNav}
      companyName={companyName}
    />
  );
};

export default Header;
